"use client";

import { APIError } from "@/lib/api/error";
import { Button } from "@primitives/button";
import { Card, CardBody } from "@primitives/card";
import { Text } from "@primitives/text";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <Card className="bg-critical-subdued w-full py-12">
          <CardBody>
            <Text className="text-center" variant="bodySm" color="critical">
              The following error has occurred!
            </Text>
            <Text className="mt-1 text-center" variant="headingMd">
              {APIError.parse(error.message)?.statusCode}:{" "}
              {APIError.parse(error.message)?.message}
              <br />
              {APIError.parse(error.message).customCode &&
                `Custom: ${APIError.parse(error.message)?.customCode}`}
            </Text>
            <Button
              className="mx-auto mt-4"
              theme="critical"
              onClick={() => reset()}
            >
              Try again
            </Button>
          </CardBody>
        </Card>
      </body>
    </html>
  );
}
