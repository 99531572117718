type TAPIErrorConstructor = {
  message?: string;
  data?: TAPIErrorBody["data"];
  customCode?: string;
} & typeof defaultConstructorConfig;

const defaultConstructorConfig = {
  statusCode: 500,
  statusMessage: "Interval Server Error",
};

export class APIError extends Error {
  statusCode: number;
  // statusMessage: string;
  data: any;
  customCode: string | null;
  name = "APIError";

  constructor(options: TAPIErrorConstructor = defaultConstructorConfig) {
    super(options?.message || options?.message);

    this.message = options?.message || "";
    this.statusCode = options.statusCode;
    this.message = options?.message || "";
    this.data = options.data;
    this.customCode = options?.customCode as any;
    Object.setPrototypeOf(this, APIError.prototype);
  }

  serialize() {
    return JSON.stringify(this.toJSON());
  }

  toJSON() {
    return {
      customCode: this.customCode,
      message: this.message,
      statusCode: this.statusCode,
    };
  }

  static parse(message: string): SerializedAPIError {
    let error: SerializedAPIError;

    try {
      error = JSON.parse(message) as SerializedAPIError;
    } catch (e) {
      console.error(e);
      return {
        message: message || "Internal server error",
        customCode: "Could not find",
        statusCode: "500",
      };
    }

    return error;
  }
}

type SerializedAPIError = {
  message: string;
  customCode: string;
  statusCode: string;
};

export class InvalidAuthToken extends APIError {
  name: string = "Invalid Auth Token";
  constructor() {
    super({
      statusCode: 401,
      message: "Authorization token is invalid, Please Signin",
      statusMessage: "Unauthorized",
    });
    Object.setPrototypeOf(this, InvalidAuthToken.prototype);
  }
}

export type TAPIErrorBody = {
  code?: string;
  message?: string;
  data?: any;
};
