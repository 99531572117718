import { cn } from "@/lib/utils";
import { ComponentPropsWithRef, ElementType, forwardRef } from "react";

import { Text } from "./text";

type CardBaseProps<C> = {
  as?: C;
};

export type CardProps<C extends ElementType> = {
  as?: C;
} & Omit<ComponentPropsWithRef<C>, keyof CardBaseProps<C>>;

export const Card = <C extends ElementType = "div">(props: CardProps<C>) => {
  const { className = "", as, ...restProps } = props;

  const Comp = as ? as : "div";

  return (
    <Comp
      className={cn(
        "bg-default flex h-max flex-col overflow-hidden rounded-lg border",
        className,
      )}
      {...restProps}
    >
      {props.children}
    </Comp>
  );
};

export const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(
  (props: CardBodyProps, ref) => {
    const {
      className = "",
      titleStyles = "",
      descriptionStyles = "",
      title,
      description,
      noBody,
      ...restProps
    } = props;
    return (
      <div ref={ref} className={cn("flex-1 p-7", className)} {...restProps}>
        {title && (
          <Text
            variant="headingLg"
            className={cn(description ? "mb-3" : "mb-7", titleStyles)}
            weight="semibold"
          >
            {title}
          </Text>
        )}
        {props.description && (
          <Text
            color="soft"
            className={cn(noBody ? "" : "mb-7", descriptionStyles)}
          >
            {props.description}
          </Text>
        )}
        {props.children}
      </div>
    );
  },
);

export const CardHeader = (props: CardHeaderProps) => {
  const { className = "", ...restProps } = props;

  return (
    <div
      className={cn("bg-subdued border-b px-7 py-4", className)}
      {...restProps}
    >
      {props.children}
    </div>
  );
};

export const CardFooter = (props: CardFooterProps) => {
  const { className = "", ...restProps } = props;
  return (
    <div
      className={cn("bg-subdued border-t px-7 py-4", className)}
      {...restProps}
    >
      {props.children}
    </div>
  );
};

interface CardBodyBaseProps {
  title?: string;
  description?: string;
  titleStyles?: string;
  descriptionStyles?: string;
  noBody?: boolean;
}

interface CardBodyProps
  extends Omit<React.ComponentPropsWithRef<"div">, keyof CardBodyBaseProps>,
    CardBodyBaseProps {}

interface CardHeaderProps extends React.ComponentPropsWithoutRef<"div"> {}

interface CardFooterProps extends React.ComponentPropsWithoutRef<"div"> {}
